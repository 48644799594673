import React from 'react';
import merge from 'lodash/merge';
import set from 'lodash/set';
import TabWrapper from 'components/bricks/components/shared/components/tab-wrapper';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import { Preset } from 'components/bricks/types/preset';
import EditorData from 'components/editor-data/EditorData';
import { AddBrickPayload } from 'components/bricks/types/brick.type';
import DefaultBriefingContent from 'components/bricks/components/shared/components/briefing-content/components';
import { MODEL_PRESETS, MODEL_UPLOAD_RESTRICTION_TYPE } from 'components/bricks/constants';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';
import { BriefingProps } from 'components/bricks/components/shared/components/briefing-content/components';
import Accordion from 'components/bricks/components/shared/components/accordion';
import PresetSelector from 'components/input/PresetSelector';
import useBrick from 'components/bricks/hooks/useBrick';
import { useAddBricksNotification } from 'components/bricks/hooks/useAddBricksNotification';
import './styles/briefing.scss';

const Briefing = ({ showTitle, isTemporaryBrick }: BriefingProps) => {
    const showAddBricksNotification = useAddBricksNotification();
    const { brick, brickChildren } = useBrick(undefined, isTemporaryBrick);

    if (!brick) return null;

    const onPresetChange = async (value: Preset[]) => {
        let previousSelectedPresets: Preset[] = [];
        if (brickChildren) {
            for (const child of brickChildren) {
                if (!child.data || !child.data.presets) continue; // If the child brick has no presets, skip it
                previousSelectedPresets = [...previousSelectedPresets, ...child.data.presets];
            }
        }
        let deletedPresets: Preset[] = [];
        let addedPresets: Preset[] = [];

        const uniquePreviousSelectedPresets = [...new Map(previousSelectedPresets.map((preset) => [preset.identifier, preset])).values()];
        uniquePreviousSelectedPresets.forEach((preset: Preset) => {
            const presetPreviousCount = previousSelectedPresets.filter((previousPreset) => previousPreset.identifier === preset.identifier).length;
            const presetNewCount = value.filter((newPreset) => newPreset.identifier === preset.identifier).length;

            const diff = presetPreviousCount - presetNewCount;

            if (diff > 0) {
                const currentDeletedPresets = new Array(diff).fill(preset);
                deletedPresets = [...deletedPresets, ...currentDeletedPresets];
            }
        });

        const uniqueCurrentSelectedPresets = value.filter((value, index, array) => array.indexOf(value) === index);
        uniqueCurrentSelectedPresets.forEach((preset: Preset) => {
            const presetPreviousCount = previousSelectedPresets.filter((previousPreset) => previousPreset.identifier === preset.identifier).length;
            const presetNewCount = value.filter((newPreset) => newPreset.identifier === preset.identifier).length;

            const diff = presetNewCount - presetPreviousCount;
            if (diff > 0) {
                const currentAddedPresets = new Array(diff).fill(preset);
                addedPresets = [...addedPresets, ...currentAddedPresets];
            }
        });

        // In a temporary brick (so the request), there is no parentId yet, so we handle that in the addBrick() function automatically
        if (!isTemporaryBrick) {
            const newBricks: AddBrickPayload[] = addedPresets.map((preset) => {
                // create new brick
                let newBrick: AddBrickPayload = {
                    campaignId: EditorData.getId(),
                    subType: 'single_asset',
                    parentId: brick?.id,
                    title: preset.title
                };

                const defaultData: unknown = BrickHelpers.getDefaultBrickData('single_asset');
                newBrick = merge(newBrick, defaultData);

                set(newBrick, MODEL_PRESETS, [preset]);
                set(newBrick, MODEL_UPLOAD_RESTRICTION_TYPE, 'restricted'); // If the preset is added, the upload restriction type is always restricted

                return newBrick;
            });

            const addedBricks = await BrickHelpers.addBricks(newBricks);
            showAddBricksNotification(addedBricks);
        }

        if (!brickChildren) {
            BricksComponentStoreHelper.setBrickModel(brick.id, MODEL_PRESETS, value);
            return;
        }

        const emptyChildBricks = brickChildren.filter((child) => !child.data || !child.data.creative);
        for (const deletedPreset of deletedPresets) {
            const indexOfEmptyBrick = emptyChildBricks.findIndex(
                (childBrick) => childBrick.data && childBrick.data.presets && childBrick.data.presets.includes(deletedPreset)
            );
            if (indexOfEmptyBrick > -1) {
                await BrickHelpers.handleDeleteBrick(emptyChildBricks[indexOfEmptyBrick].id);
            }
        }

        BricksComponentStoreHelper.setBrickModel(brick.id, MODEL_PRESETS, value);
    };

    return (
        <TabWrapper classes={{ root: 'asset-set-briefing' }}>
            <DefaultBriefingContent noMargin showTitle={showTitle} isTemporaryBrick={isTemporaryBrick} expanded={false} />

            <Accordion title="Asset restrictions">
                <PresetSelector value={BrickHelpers.getBrickPresetsFromChildren(brick)} onMutation={onPresetChange} multiple />
            </Accordion>
        </TabWrapper>
    );
};

export default Briefing;
