import { BrickSetup } from 'components/bricks/types/brick.type';
import baseSetup from '../all/setup';

const setup: BrickSetup = {
    ...baseSetup,
    subType: 'creative_pdf',
    title: 'PDF creative',
    channel: 'assets',
    description: 'A creative based on a PDF template',
    addBrickText: 'Add PDF creative brick',
    config: {
        ...baseSetup.config,
        general: {
            ...baseSetup?.config?.general,
            creativeSelector: {
                ...baseSetup?.config?.general?.creativeSelector,
                templateTypes: ['dynamicPDF', 'dynamicPDFDesigned']
            }
        }
    }
};

export default setup;
